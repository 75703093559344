import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import queryString from 'query-string'
import Cookies from 'js-cookie'
// import semver from 'semver'
import {hotjar} from 'react-hotjar'
import ReactPixel from 'react-facebook-pixel'
import {initialise} from '../actions/app_actions'
import AuthRoute from '../utils/authRoute'
import Footer from '../components/Footer'
import Header from '../components/Header'
import CookieConsent from '../components/CookieConsent'
import Home from './Home'
import Login from './Login'
import config from '../config'
import {AnimatePresence} from 'framer-motion'

const HomeDiscover = React.lazy(() => import('./Home/HomeDiscover'))
const Beats = React.lazy(() => import('./Beats'))
const ResetPassword = React.lazy(() => import('./Login/ResetPassword'))
const Logout = React.lazy(() => import('./Login/Logout'))
const ProducerArea = React.lazy(() => import('./ProducerArea'))
const AffiliateArea = React.lazy(() => import('./AffiliateArea'))
const AvisoLegal = React.lazy(() => import('./Footerpages/AvisoLegal'))
const PaginasLegales = React.lazy(() => import('./Footerpages/PaginasLegales'))
const Faq = React.lazy(() => import('./Footerpages/Faq'))
const PoliticaCookies = React.lazy(() => import('./Footerpages/PoliticaCookies'))
const PoliticaPrivacidad = React.lazy(() => import('./Footerpages/PoliticaPrivacidad'))
const SignUp = React.lazy(() => import('./SignUp'))
const UserArea = React.lazy(() => import('./UserArea'))
const TerminosCondiciones = React.lazy(() => import('./Footerpages/TerminosCondiciones'))

const App = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const {/* appVersion,*/ cookieConsent, isEuropean, role} = useSelector(state => ({
    // appVersion: state.app.appVersion,
    // noInternet: state.app.noInternet,
    cookieConsent: state.visitor.cookieConsent,
    isEuropean: state.visitor.isEuropean,
    role: state.user.role
  }))

  //Lanzamos un log de bienvenida con la version de la App
  useEffect(()=>{
    console.info(`Beats4seven Frontend v${config.appVersion}`)
  },[])

  //Inicializar Pixel de Facebook
  useEffect(()=>{
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    }
    ReactPixel.init(config.facebookPixel, {}, options)
    ReactPixel.pageView()
  },[])

  //Consentimiento de cookies
  useEffect(()=>{
    if (cookieConsent) {
      hotjar.initialize(config.hotjarId, 6)
      
      if (!document.querySelector('[src="https://tracker.metricool.com/resources/be.js"]')) {
        const script = document.createElement('script')
        const execution = () => window.beTracker.t({hash:config.metricoolKey})
        script.type = 'text/javascript'
        script.src = 'https://tracker.metricool.com/resources/be.js'
        script.onreadystatechange = execution
        script.onload = execution
        document.head.appendChild(script)
      }
    }
  },[cookieConsent])

 
  // Script de Sendinblue
  useEffect(()=>{
    window.sib = {
      equeue: [],
      client_key: config.sendinblueKey
    }
    /* OPTIONAL: email for identify request*/
    // window.sib.email_id = 'example@domain.com';
    window.sendinblue = {}
    for (var j = ['track', 'identify', 'trackLink', 'page'], i = 0; i < j.length; i++) {
      (function(k) {
        window.sendinblue[k] = function() {
          var arg = Array.prototype.slice.call(arguments);
          (window.sib[k] || function() {
            var t = {}
            t[k] = arg
            window.sib.equeue.push(t)
          })(arg[0], arg[1], arg[2])
        }
      })(j[i])
    }

    const n = document.createElement('script')
    n.type = 'text/javascript'
    n.id = 'sendinblue-js'
    n.async = !0
    n.src = `https://sibautomation.com/sa.js?key=${window.sib.client_key}`
    document.head.appendChild(n)
    window.sendinblue.page()
  },[])


  //Comprueba que el back-end esté activo,
  //Y si tenemos la ultima version del front
  useEffect(()=>{
    dispatch(initialise())
  },[dispatch, role])



  //La API envia constantemente la version del front
  //Comparar contra la config
  // useEffect(()=>{
  //   if (semver.gt(appVersion, config.appVersion)) {
  //     window.location.reload()
  //   }
  // },[appVersion]) 

  
  //Si no hay conexión a internet, muestra una advertencia
  // useEffect(()=>{
  //   if (noInternet) {
  //     //TODO: En un futuro debe activar una bandera para mostrar la advertencia
  //   }
  // },[noInternet])


  //Si en la url hay una ID de referencia, guardar en el store
  useEffect(() => {
    const query = queryString.parse(location.search)
    if (!isNaN(query.wpam_id)) {
      Cookies.set('wpam_id', query.wpam_id, {expires: 365, domain: config.domain})
      navigate({pathname: location.pathname})
    }

    if (query.coupon) {
      Cookies.set('coupon', query.coupon, {expires: 365, domain: config.domain})
      navigate({pathname: location.pathname})
    }
  },[location.pathname, location.search, navigate])


  if (['/', '/discover', '/landing'].includes(location.pathname)) {
    return (
      <React.Suspense fallback={<i className="fal fa-sync fa-spin" />}>
        <Routes>
          <Route path="/" element={<AuthRoute roles={['guest']} ><Home /></AuthRoute>}/>
          <Route path="/discover" element={<AuthRoute roles={['guest']} ><HomeDiscover /></AuthRoute>}/>
        </Routes>
      </React.Suspense>
    )
  }

  return (
    <React.Fragment>
      {isEuropean ? <CookieConsent /> : ''}
      <div className="w-full pb-32 bg-gradient-to-r from-b4s-blue-5 to-b4s-purple-1  text-white">
        <div className="container">
          <Header />
          <AnimatePresence>
            <React.Suspense fallback={<i className="fal fa-sync fa-spin" />}>
              <Routes location={location}>
                <Route path="/beats" element={<Beats />}/>
                <Route path="/beat/:beat" element={<Beats />}/>
                <Route path="/login" element={<AuthRoute roles={['guest']} ><Login /></AuthRoute>}/>
                <Route path="/reset-password/:token" element={<AuthRoute roles={['guest']} ><ResetPassword /></AuthRoute>}/>
                <Route path="/sign-up/*" element={<SignUp />} />
                <Route path="/logout" element={<AuthRoute roles={['admin','active', 'inactive']} ><Logout /></AuthRoute>}/>
                <Route path="/user-area/*" element={<UserArea />}/>
                <Route path="/affiliate-area/*" element={<AuthRoute roles={['affiliate']} ><AffiliateArea /></AuthRoute>}/>
                <Route path="/producer-area/*" element={<AuthRoute roles={['producer']} ><ProducerArea /></AuthRoute>}/>
                <Route path="/terms-conditions" element={<TerminosCondiciones />} />
                <Route path="/privacy-policy" element={<PoliticaPrivacidad />} />
                <Route path="/legal-notice" element={<AvisoLegal />} />
                <Route path="/cookies-policy" element={<PoliticaCookies />} />
                <Route path="/legal-pages" element={<PaginasLegales />} />
                <Route path="/faq" element={<Faq />} />
              </Routes>
            </React.Suspense>
          </AnimatePresence>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default App
