import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {FormattedMessage} from 'react-intl'
import Button from '../Button'
import {Tabs, Tab} from '../Tabs'
import check from '../../assets/img/check.svg'


const Pricing = ({buttonAction, border, className}) => {
  const app = useSelector((state) => ({
    memberships: state.app.memberships,
    role: state.user.role,
  }))

  const [monthly, setMonthly] = useState(false)
  const [yearly, setYearly] = useState(false)
  const [cycle, setCycle] = React.useState(1)

  useEffect(() => {
    if (app.memberships) {
      const monthly = app.memberships.filter((item) => item.months === 1 && item.enabled === true)
      const yearly = app.memberships.filter((item) => item.months === 12 && item.enabled === true)
      if (monthly.length > 0) setMonthly(monthly[0])
      if (yearly.length > 0) setYearly(yearly[0])
    }
  }, [app.memberships])

  const borderStyle = 'border-white border border-opacity-20'

  return (
    <div className={`w-full flex flex-col justify-center items-center ${className}`}>

      <Tabs className="mb-5" active={cycle}>
        <Tab index={1} onClick={() => setCycle(1)}><FormattedMessage  id="pages.userarea.menu.beatks"  defaultMessage="Mensual" /></Tab>
        <Tab index={2} onClick={() => setCycle(2)}><FormattedMessage  id="pages.userarea.menu.sjub"  defaultMessage="Anual" /></Tab>
      </Tabs>
      <p className="mb-10 text-sm text-b4s-gold-1">
        <FormattedMessage id="component.pricing.anualAdvantage" defaultMessage="1 mes gratis + 20 créditos extra con el plan anual" />
      </p>

      <div className="w-full flex flex-wrap justify-center">
        {cycle === 1 && (
          <div className="w-full md:px-4 lg:w-1/2 mb-8 md:mb-0">
            <div className={`flex flex-col relative z-10 overflow-hidden rounded-xl py-10 px-8 h-full sm:p-12 lg:py-10 lg:px-6 xl:p-12 bg-b4s-blue-1 ${border ? borderStyle : ''} shadow-b4s-blue-5 shadow-xl`}>
              <span className="text-primary mb-4 block text-lg font-semibold">
                <FormattedMessage id="component.pricing.monthly" defaultMessage="Mensual" />
              </span>
              <h2 className="text-dark mb-5 text-[42px] font-bold flex items-center">
                {monthly.price !== monthly.discountedPrice && (
                  <del className="text-gray-500 mr-2">
                    {monthly.price}{monthly.currencySymbol}
                  </del>
                )}
                {monthly.discountedPrice}{monthly.currencySymbol}
                <span className="text-body-color text-base font-medium">
                  {' '}
                  <FormattedMessage id="component.pricing.perMonth" defaultMessage="/ mes" />{' '}
                </span>
                {yearly.price !== yearly.discountedPrice && (
                  <span className="text-b4s-blue-3 bg-b4s-purple-1 p-1 ml-3 rounded text-sm align-top mt-[-35px]">
                    {monthly.discountPercent}%
                  </span>
                )}
              </h2>
              {monthly.price !== monthly.discountedPrice && (
                <label className="text-b4s-blue-3 text-sm mb-3 flex items-center bg-b4s-purple-1 p-3 rounded">
                  <FormattedMessage id="component.pricing.couponApplied" defaultMessage="Cupón aplicado! Descuento solo para el primer mes." />
                </label>
              )}
              <p className="text-body-color mb-8 border-b border-[#F2F2F2] pb-8 text-base">
                <FormattedMessage id="component.pricing.monthlyDescription" defaultMessage="Descarga beats cada mes y cancela cuando quieras. Ideal para saber lo bien que trabajamos en Beats4seven." />
              </p>
              <div className="mb-7 grow">
                <div className="mb-2 flex flex-row items-center">
                  <img className="h-6 w-6 mr-2" src={check} alt="yes" /> 
                  <p className="text-body-color text-sm leading-loose">
                    <FormattedMessage id="component.pricing.mensualDesc1" defaultMessage="Descarga {beats} beats cada mes" values={{beats: 5}} />
                  </p>
                </div>
                <div className="mb-2 flex flex-row items-center">
                  <img className="h-6 w-6 mr-2" src={check} alt="yes" /> 
                  <p className="text-body-color text-sm leading-loose">
                    <FormattedMessage id="component.pricing.mensualDesc2" defaultMessage="En formato Wav sin marca de agua" />
                  </p>
                </div>
                <div className="mb-2 flex flex-row items-center">
                  <img className="h-6 w-6 mr-2" src={check} alt="yes" /> 
                  <p className="text-body-color text-sm leading-loose whitespace-nowrap">
                    <FormattedMessage id="component.pricing.mensualDesc3" defaultMessage="Incluye las pistas de cada beat ( STEMS )" />
                  </p>
                </div>
                <div className="mb-2 flex flex-row items-center">
                  <img className="h-6 w-6 mr-2" src={check} alt="yes" /> 
                  <p className="text-body-color text-sm leading-loose">
                    <FormattedMessage id="component.pricing.mensualDesc4" defaultMessage="Sube tus canciones donde quieras" />
                  </p>
                </div>
                <div className="mb-2 flex flex-row items-center">
                  <img className="h-6 w-6 mr-2" src={check} alt="yes" /> 
                  <p className="text-body-color text-sm leading-loose">
                    <FormattedMessage id="component.pricing.mensualDesc5" defaultMessage="Monetiza tus canciones" />
                  </p>
                </div>
                <div className="mb-2 flex flex-row items-center">
                  <img className="h-6 w-6 mr-2" src={check} alt="yes" /> 
                  <p className="text-body-color text-sm leading-loose">
                    <FormattedMessage id="component.pricing.mensualDesc6" defaultMessage="Accede a mezclas PRO" />
                  </p>
                </div>
              </div>

              <Button onClick={() => buttonAction(monthly.id)}>
                <FormattedMessage id="component.pricing.chooseButton" defaultMessage="Selecciona" />
              </Button>
              <Background />
            </div>
          </div>
        )}

        {cycle === 2 && (
          <div className="w-full md:px-4 md:w-1/2">
            <div className={`flex flex-col relative z-10 overflow-hidden rounded-xl py-10 px-8 h-full sm:p-12 lg:py-10 lg:px-6 xl:p-12 bg-b4s-blue-1 ${border ? borderStyle : ''} shadow-b4s-blue-5 shadow-xl`}>
              <span className="text-primary mb-4 block text-lg font-semibold">
                <FormattedMessage id="component.pricing.annual" defaultMessage="Anual" />
              </span>
              <h2 className="text-dark mb-5 text-[42px] font-bold flex items-center">
                {yearly.price !== yearly.discountedPrice && (
                  <del className="text-gray-500 mr-2">
                    {yearly.price}{yearly.currencySymbol}
                  </del>
                )}
                {yearly.discountedPrice}{yearly.currencySymbol}
                <span className="text-body-color text-base font-medium">
                  {' '}
                  <FormattedMessage id="component.pricing.perYear" defaultMessage="/ año" />{' '}
                </span>
                {yearly.price !== yearly.discountedPrice && (
                  <span className="text-b4s-blue-3 bg-b4s-purple-1 p-1 ml-3 rounded text-sm align-top mt-[-35px]">
                    {yearly.discountPercent}%
                  </span>
                )}
              </h2>
              {yearly.price !== yearly.discountedPrice && (
                <label className="text-b4s-blue-3 text-sm mb-3 flex items-center bg-b4s-purple-1 p-3 rounded">
                  <FormattedMessage id="component.pricing.couponApplied" defaultMessage="Cupón aplicado! Descuento solo para el primer mes." />
                </label>
              )}
              <p className="text-body-color mb-8 border-b border-[#F2F2F2] pb-8 text-base">
                <FormattedMessage id="component.pricing.annualDesc" defaultMessage="Si ya nos conoces, este es tu plan. 1 año entero en Beats4seven, con 1 mes gratis y 20 beats extra. Ni lo pienses!" />
              </p>
              <div className="mb-7">
                <div className="mb-2 flex flex-row items-center">
                  <img className="h-6 w-6 mr-2" src={check} alt="yes" /> 
                  <p className="text-body-color text-sm leading-loose">
                    <FormattedMessage id="component.pricing.annualDesc1" defaultMessage="Descarga {beats} beats de inmediato" values={{beats: 80}}/>
                  </p>
                </div>
                <div className="mb-2 flex flex-row items-center">
                  <img className="h-6 w-6 mr-2" src={check} alt="yes" /> 
                  <p className="text-body-color text-sm leading-loose">
                    <FormattedMessage id="component.pricing.annualDesc2" defaultMessage="En formato Wav sin marca de agua" />
                  </p>
                </div>
                <div className="mb-2 flex flex-row items-center">
                  <img className="h-6 w-6 mr-2" src={check} alt="yes" /> 
                  <p className="text-body-color text-sm leading-loose whitespace-nowrap">
                    <FormattedMessage id="component.pricing.annualDesc3" defaultMessage="Incluye las pistas de cada beat ( STEMS )" />
                  </p>
                </div>
                <div className="mb-2 flex flex-row items-center">
                  <img className="h-6 w-6 mr-2" src={check} alt="yes" /> 
                  <p className="text-body-color text-sm leading-loose">
                    <FormattedMessage id="component.pricing.annualDesc4" defaultMessage="Sube tus canciones donde quieras" />
                  </p>
                </div>
                <div className="mb-2 flex flex-row items-center">
                  <img className="h-6 w-6 mr-2" src={check} alt="yes" /> 
                  <p className="text-body-color text-sm leading-loose">
                    <FormattedMessage id="component.pricing.annualDesc5" defaultMessage="Monetiza tus canciones" />
                  </p>
                </div>
                <div className="mb-2 flex flex-row items-center">
                  <img className="h-6 w-6 mr-2" src={check} alt="yes" /> 
                  <p className="text-body-color text-sm leading-loose">
                    <FormattedMessage id="component.pricing.annualDesc6" defaultMessage="Accede a mezclas PRO" />
                  </p>
                </div>
                <div className="mb-2 flex flex-row items-center">
                  <img className="h-6 w-6 mr-2" src={check} alt="yes" /> 
                  <p className="text-body-color text-sm leading-loose">
                    <FormattedMessage id="component.pricing.annualDesc7" defaultMessage="1 mes gratis" />
                  </p>
                </div>
                <div className="mb-2 flex flex-row items-center">
                  <img className="h-6 w-6 mr-2" src={check} alt="yes" /> 
                  <p className="text-body-color text-sm leading-loose">
                    <FormattedMessage id="component.pricing.annualDesc8" defaultMessage="{credits} créditos de regalo" values={{credits: 20}} />
                  </p>
                </div>
              </div>

              <Button onClick={() => buttonAction(yearly.id)}>
                <FormattedMessage id="component.pricing.chooseButton" defaultMessage="Selecciona" />
              </Button>
              <Background />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const Background = () => {
  return (
    <div>
      <span className="absolute right-0 top-7 z-[-1]">
        <svg
          width="77"
          height="172"
          viewBox="0 0 77 172"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="86"
              y1="0"
              x2="86"
              y2="172"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="fill-b4s-gold-1" stop-opacity="0.09" />
              <stop offset="1" stop-color="#C4C4C4" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </span>
      <span className="absolute right-4 top-4 z-[-1]">
        <svg
          width="41"
          height="89"
          viewBox="0 0 41 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="38.9138"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 38.9138 87.4849)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="38.9138"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 38.9138 74.9871)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="38.9138"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 38.9138 62.4892)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="38.9138"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 38.9138 38.3457)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="38.9138"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 38.9138 13.634)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="38.9138"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 38.9138 50.2754)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="38.9138"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 38.9138 26.1319)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="38.9138"
            cy="1.42021"
            r="1.42021"
            transform="rotate(180 38.9138 1.42021)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="26.4157"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 26.4157 87.4849)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="26.4157"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 26.4157 74.9871)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="26.4157"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 26.4157 62.4892)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="26.4157"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 26.4157 38.3457)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="26.4157"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 26.4157 13.634)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="26.4157"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 26.4157 50.2754)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="26.4157"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 26.4157 26.1319)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="26.4157"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 26.4157 1.4202)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="13.9177"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 13.9177 87.4849)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="13.9177"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 13.9177 74.9871)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="13.9177"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 13.9177 62.4892)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="13.9177"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 13.9177 38.3457)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="13.9177"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 13.9177 13.634)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="13.9177"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 13.9177 50.2754)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="13.9177"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 13.9177 26.1319)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="13.9177"
            cy="1.42019"
            r="1.42021"
            transform="rotate(180 13.9177 1.42019)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="1.41963"
            cy="87.4849"
            r="1.42021"
            transform="rotate(180 1.41963 87.4849)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="1.41963"
            cy="74.9871"
            r="1.42021"
            transform="rotate(180 1.41963 74.9871)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="1.41963"
            cy="62.4892"
            r="1.42021"
            transform="rotate(180 1.41963 62.4892)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="1.41963"
            cy="38.3457"
            r="1.42021"
            transform="rotate(180 1.41963 38.3457)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="1.41963"
            cy="13.634"
            r="1.42021"
            transform="rotate(180 1.41963 13.634)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="1.41963"
            cy="50.2754"
            r="1.42021"
            transform="rotate(180 1.41963 50.2754)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="1.41963"
            cy="26.1319"
            r="1.42021"
            transform="rotate(180 1.41963 26.1319)"
            className="fill-b4s-gold-1"
          />
          <circle
            cx="1.41963"
            cy="1.4202"
            r="1.42021"
            transform="rotate(180 1.41963 1.4202)"
            className="fill-b4s-gold-1"
          />
        </svg>
      </span>
    </div>
  )
}

export default Pricing
