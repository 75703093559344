import * as types from './types'
import {apicaller} from '../utils/apicaller'
import config from '../config'
import Cookies from 'js-cookie'

export const initialise = () => async (dispatch, getState) => {
  try {
    const data = await apicaller({
      url: `${config.backUrl}/v2/initialise`,
      withCredentials: true,
    }, dispatch)

    dispatch({type: types.SET_APP_VERSION, payload: data.version})
    dispatch({type: types.LOAD_MEMBERSHIPS, payload: data.memberships})

    if (data.gdprRequired) {
      dispatch({type: types.CHECK_GDPR_REQUIRED_TRUE})
    } else {
      dispatch({type: types.CHECK_GDPR_REQUIRED_FALSE})
    }

    if (data.errors) {
      if (data.errors.includes(4018)) {
        Cookies.remove('coupon', {domain: config.domain})
      }
      
      dispatch({type: types.SET_ERRORS, payload: data.errors})
    } else {
      dispatch({type: types.SET_ERRORS, payload: []})
    }
  

    if (data.userInfo) {
      const {userInfo} = data
      //Borrar mensaje temporal si se detecta un cambio de suscripcion
      const currentCredits = getState().user.credits
      if (currentCredits !== null && currentCredits > data.userInfo.credits) {
        dispatch({type: types.SET_USER_DISABLE_AWAITING_PAYMENT})
      }

      dispatch({
        type: types.GET_USER_SUCCESS,
        payload: {
          username: userInfo.username,
          firstname: userInfo.firstname,
          lastname: userInfo.lastname,
          language: userInfo.language,
          credits: userInfo.credits,
          role: userInfo.role,
        }
      })
    } else {
      dispatch({type: types.RESET_USER})
    }

    if (Cookies.get('coupon') === undefined && typeof data.coupon === 'string') {
      Cookies.set('coupon', data.coupon, {expires: 365, domain: config.domain})
    }
      
  } catch (err) {
    dispatch({type: types.INITIALIZE_FAIL})
  }
}

export const sendErrorReport = (error, errorInfo) => async (dispatch, getState) => {
  try {
    const store = btoa(JSON.stringify(getState()))
    await apicaller({
      url: `${config.backUrl}/v2/error/report`,
      data:{
        error,
        errorInfo,
        store,
        appVersion: config.appVersion
      },
      method: 'post',
    }, dispatch).catch(err => {})
      
    dispatch(clearStore())
  } catch (e) {
        
  }
}

export const clearStore = () => dispatch => {
  dispatch({type: types.RESET_APP})
  dispatch({type: types.RESET_BEATS})
  dispatch({type: types.RESET_SIGNUP})
  dispatch({type: types.RESET_USER})
  dispatch({type: types.RESET_USER_BEATS})
  dispatch({type: types.RESET_USER_PROFILE})
  dispatch({type: types.RESET_USER_SUBSCRIPTION})
  dispatch({type: types.RESET_PRODUCER})
}
