import * as types from '../actions/types'

const initialState = {
  membership: '',
  isLoading: false,
  error: false,
  errorCode: null,
  verifyTries: 0
}

const signupReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.SIGNUP_SET_MEMBERSHIP:
    return  {...state, membership: action.payload}
  case types.SIGNUP_LOAD_REGISTRATION:
    return  {...state, isLoading: true, error: false}
  case types.SIGNUP_LOAD_REGISTRATION_SUCCESS:
    return  {...state, isLoading: false, error: false}
  case types.SIGNUP_LOAD_REGISTRATION_FAIL:
    return  {...state, isLoading: false, error: true, errorCode: action.payload}
  case types.SIGNUP_VERIFY_SUBSCRIPTION:
    return {...state, verifyTries: state.verifyTries+1}
  case types.RESET_SIGNUP:
    return {...initialState}
  default: 
    return state
  }
}

export default signupReducer
