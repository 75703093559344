import {createStore,applyMiddleware, compose} from 'redux'
import {persistStore, persistCombineReducers} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import affiliateReducer from './reducer_affiliate'
import appReducer from './reducer_app'
import beatsReducer from './reducer_beats'
import feedReducer from './reducer_feed'
import producerReducer from './reducer_producer'
import userReducer from './reducer_user'
import userLoginReducer from './reducer_user_login'
import userBeatsReducer from './reducer_user_beats'
import signupReducer from './reducer_signup'
import userProfileReducer from './reducer_user_profile'
import userSubscriptionReducer from './reducer_user_subscription'
import visitorReducer from './reducer_visitor'


const reducers = {
  affiliate: affiliateReducer,
  app: appReducer,
  beats: beatsReducer,
  feed: feedReducer,
  producer: producerReducer,
  user: userReducer,
  userLogin: userLoginReducer,
  userBeats: userBeatsReducer,
  userProfile: userProfileReducer,
  userSubscription: userSubscriptionReducer,
  signup: signupReducer,
  visitor: visitorReducer
}

const config = {
  key: 'root',
  storage,
  whitelist: ['user', 'visitor', 'signup']
}

const reducer = persistCombineReducers(config, reducers)

const configureStore = () => {
  let composeEnhancers = null
  if (process.env.NODE_ENV === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  } else {
    composeEnhancers = compose 
  } 

  let store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)))


  let persistor = persistStore(store)

  return {persistor, store}
}

export default configureStore
