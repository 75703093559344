import ReactPixel from 'react-facebook-pixel'
import {apicaller} from '../utils/apicaller'
import * as types from './types'
import config from '../config'

export const setSignupSub = sub => {
  ReactPixel.track('Lead', {content_name: sub}) 
  return {
    type: types.SIGNUP_SET_MEMBERSHIP,
    payload: sub
  }
}

export const setSignupPersonal = details => async (dispatch) => {
  dispatch({type:types.SIGNUP_LOAD_REGISTRATION})
  try {
    const login = await apicaller({
      url: `${config.backUrl}/v2/signup`,
      data: details,
      method: 'post',
      withCredentials: true,
    },dispatch)

    //Recibimos la información del usuario nuevo creado
    //Y lo conectamos
    dispatch({
      type: 'GET_USER_SUCCESS',
      payload: {
        username: login.username,
        firstname: login.firstname,
        lastname: login.lastname,
        email: login.email,
        credits: 0,
        role: 'inactive',
      }
    })

    //Avisamos que el registro fue exitoso
    dispatch({
      type: types.SIGNUP_LOAD_REGISTRATION_SUCCESS
    })

    //Registro de usuario exitoso
    window.sendinblue.track('CompleteRegistration',{
      email: login.user_email,
      nombre: details.firstname,
      apellidos : details.lastname,
      username: details.username,
      id : login.userid,
      idioma : login.language
    })
    
    ReactPixel.track('CompleteRegistration', {value: 0, currency: 'USD'})
    
  } catch (err) {
    dispatch({
      type: types.SIGNUP_LOAD_REGISTRATION_FAIL,
      payload: err.data.errorCode
    })
  }
}

export const setAwaitingPayment = () => async (dispatch) => {
  dispatch({type: types.SET_USER_AWAITING_PAYMENT})
}


export const verifyOnboard = () => async (dispatch) => {
  dispatch({type: types.GET_USER})
  
  try {
    const data = await apicaller({
      url: `${config.backUrl}/v2/user/status`,
    }, dispatch)

    //dispatch information
    dispatch({
      type: types.GET_USER_SUCCESS,
      payload: {
        username: data.username,
        firstname: data.firstname,
        lastname: data.lastname,
        language: data.language,
        credits: data.credits,
        role: data.role,
      }
    })

    dispatch({type: types.SIGNUP_VERIFY_SUBSCRIPTION})
  } catch (err) {
  }
}
