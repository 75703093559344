import React from 'react'
//import styles from './Tabs.module.scss'

export const Tabs = props => {

  return (
    <div {...props} className={`flex flex-row text-b4s-blue-3 ${props.className}`}>
      {props.children.map((tab) => (
        //We are injecting the active tab id into the tab
        {...tab, props: {...tab.props, active:props.active}}
      ))}
    </div>
  )
  
}

export const Tab = props => {
  const {active, index} = props
  let isActiveTab = false
  if (parseInt(active) === parseInt(index)) {
    isActiveTab = true
  }

  return (
    <div {...props} className={`pb-2 px-4 ${isActiveTab && 'border-b-4 border-b4s-pink-1'} cursor-pointer whitespace-nowrap hover:border-b-4 hover:border-b4s-blue-3`}>
      <span>{props.children}</span>
    </div>    
  )
}
