import * as types from '../actions/types'

const initialState = {
  appVersion: '0.0.0',
  tokenHasExpired: false,
  noInternet: false,
  memberships: [],
  errors: [],
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.EXPIRED_TOKEN:
    return  {...initialState, tokenHasExpired: true}
  case types.SET_APP_VERSION:
    return {...state, appVersion: action.payload}
  case types.LOAD_MEMBERSHIPS:
    return  {...state, memberships: action.payload}
  case types.SET_ERRORS:
    return  {...state, errors: action.payload}
  case types.INITIALIZE_FAIL:
    return  {...initialState, noInternet: true}
  case types.RESET_APP:
    return  {...initialState}
  default: 
    return state
  }
}

export default appReducer
