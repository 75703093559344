export const USER_SIGNIN = 'USER_SIGNIN'
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_SIGNOUT = 'USER_SIGNOUT'
export const USER_SIGNOUT_FAIL = 'USER_SIGNOUT_FAIL'
export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS'
export const REMEMBER_PASSWORD = 'REMEMBER_PASSWORD'
export const REMEMBER_PASSWORD_FAIL = 'REMEMBER_PASSWORD_FAIL'
export const REMEMBER_PASSWORD_SUCCESS = 'REMEMBER_PASSWORD_SUCCESS'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export const RESET_USER = 'RESET_USER'
export const RESET_USER_LOGIN = 'RESET_USER_LOGIN'

export const GET_BEATS = 'GET_BEATS'
export const GET_BEATS_FAIL = 'GET_BEATS_FAIL'
export const GET_BEATS_SUCCESS = 'GET_BEATS_SUCCESS'
export const RESET_BEATS = 'RESET_BEATS'

export const GET_USER_FEED = 'GET_USER_FEED'
export const GET_USER_FEED_FAIL = 'GET_USER_FEED_FAIL'
export const GET_USER_FEED_SUCCESS = 'GET_USER_FEED_SUCCESS'
export const SET_USER_FEED_LIKE_SUCCESS = 'SET_USER_FEED_LIKE_SUCCESS'
export const RESET_FEED = 'RESET_FEED'

export const GET_USER = 'GET_USER'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'

export const GET_USER_BEATS = 'GET_USER_BEATS'
export const GET_USER_BEATS_FAIL = 'GET_USER_BEATS_FAIL'
export const GET_USER_BEATS_SUCCESS = 'GET_USER_BEATS_SUCCESS'
export const LOAD_USER_BEAT = 'DOWNLOAD_USER_BEAT'
export const LOAD_USER_BEAT_SUCCESS = 'LOAD_USER_BEAT_SUCCESS'
export const LOAD_USER_BEAT_FAIL = 'LOAD_USER_BEAT_FAIL'
export const LOAD_USER_BEAT_UPGRADE = 'DOWNLOAD_USER_BEAT_UPGRADE'
export const LOAD_USER_BEAT_UPGRADE_SUCCESS = 'LOAD_USER_BEAT_UPGRADE_SUCCESS'
export const LOAD_USER_BEAT_UPGRADE_FAIL = 'LOAD_USER_BEAT_UPGRADE_FAIL'
export const SET_USER_BEAT_PURCHASE_SUCCESS = 'SET_USER_BEAT_PURCHASE_SUCCESS'
export const SET_USER_BEAT_PURCHASE_FAIL = 'SET_USER_BEAT_PURCHASE_FAIL'
export const SET_USER_BEAT_FAVOURITE_SUCCESS = 'SET_USER_BEAT_FAVOURITE_SUCCESS'
export const RESET_USER_BEATS = 'RESET_USER_BEATS'

export const LOAD_USER_BEAT_LICENSE = 'LOAD_USER_BEAT_LICENSE'
export const LOAD_USER_BEAT_LICENSE_SUCCESS = 'LOAD_USER_BEAT_LICENSE_SUCCESS'
export const LOAD_USER_BEAT_LICENSE_FAIL = 'LOAD_USER_BEAT_LICENSE_FAIL'

export const GET_USER_CREDITS = 'GET_USER_CREDITS'
export const GET_USER_CREDITS_FAIL = 'GET_USER_CREDITS_FAIL'
export const GET_USER_CREDITS_SUCCESS = 'GET_USER_CREDITS_SUCCESS'

export const GET_USER_SUBSCRIPTION = 'GET_USER_SUBSCRIPTION'
export const GET_USER_SUBSCRIPTION_FAIL = 'GET_USER_SUBSCRIPTION_FAIL'
export const GET_USER_SUBSCRIPTION_SUCCESS = 'GET_USER_SUBSCRIPTION_SUCCESS'
export const CHOOSE_USER_SUBSCRIPTION = 'CHOOSE_USER_SUBSCRIPTION'
export const SET_USER_SUBSCRIPTION_PAYPAL = 'SET_USER_SUBSCRIPTION_PAYPAL'
export const SET_USER_SUBSCRIPTION_PAYPAL_FAIL = 'SET_USER_SUBSCRIPTION_PAYPAL_FAIL'
export const SET_USER_SUBSCRIPTION_PAYPAL_SUCCESS = 'SET_USER_SUBSCRIPTION_PAYPAL_SUCCESS'
export const SET_USER_SUBSCRIPTION_STRIPE = 'SET_USER_SUBSCRIPTION_STRIPE'
export const SET_USER_SUBSCRIPTION_STRIPE_FAIL = 'SET_USER_SUBSCRIPTION_STRIPE_FAIL'
export const SET_USER_SUBSCRIPTION_STRIPE_SUCCESS = 'SET_USER_SUBSCRIPTION_STRIPE_SUCCESS'
export const GET_USER_SUBSCRIPTION_STRIPE = 'GET_USER_SUBSCRIPTION_STRIPE'
export const GET_USER_SUBSCRIPTION_STRIPE_FAIL = 'GET_USER_SUBSCRIPTION_STRIPE_FAIL'
export const GET_USER_SUBSCRIPTION_STRIPE_SUCCESS = 'GET_USER_SUBSCRIPTION_STRIPE_SUCCESS'
export const CANCEL_USER_SUBSCRIPTION = 'CANCEL_USER_SUBSCRIPTION'
export const CANCEL_USER_SUBSCRIPTION_FAIL = 'CANCEL_USER_SUBSCRIPTION_FAIL'
export const CANCEL_USER_SUBSCRIPTION_SUCCESS = 'CANCEL_USER_SUBSCRIPTION_SUCCESS'
export const RESET_USER_SUBSCRIPTION_ERRORS = 'RESET_USER_SUBSCRIPTION_ERRORS'
export const RESET_USER_SUBSCRIPTION = 'RESET_USER_SUBSCRIPTION'

export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const SET_USER_PROFILE_FAIL = 'SET_USER_PROFILE_FAIL'
export const SET_USER_PROFILE_SUCCESS = 'SET_USER_PROFILE_SUCCESS'
export const SET_USER_PROFILE_PASSWORD = 'SET_USER_PROFILE_PASSWORD'
export const SET_USER_PROFILE_PASSWORD_FAIL = 'SET_USER_PROFILE_PASSWORD_FAIL'
export const SET_USER_PROFILE_PASSWORD_SUCCESS = 'SET_USER_PROFILE_PASSWORD_SUCCESS'
export const SET_USER_AWAITING_PAYMENT = 'SET_USER_AWAITING_PAYMENT'
export const SET_USER_DISABLE_AWAITING_PAYMENT = 'SET_USER_DISABLE_AWAITING_PAYMENT'
export const RESET_USER_PROFILE = 'RESET_USER_PROFILE'

export const SIGNUP_SET_AFFILIATE = 'SIGNUP_SET_AFFILIATE'
export const SIGNUP_SET_MEMBERSHIP = 'SIGNUP_SET_MEMBERSHIP'
export const SIGNUP_LOAD_REGISTRATION = 'SIGNUP_LOAD_REGISTRATION'
export const SIGNUP_LOAD_REGISTRATION_FAIL = 'SIGNUP_LOAD_REGISTRATION_FAIL'
export const SIGNUP_LOAD_REGISTRATION_SUCCESS = 'SIGNUP_LOAD_REGISTRATION_SUCCESS'
export const SIGNUP_VERIFY_SUBSCRIPTION = 'SIGNUP_VERIFY_SUBSCRIPTION'
export const RESET_SIGNUP = 'RESET_SIGNUP'

//Affilaite
export const GET_AFFILIATE_SUBS = 'GET_AFFILIATE_SUBS'
export const GET_AFFILIATE_SUBS_FAIL = 'GET_AFFILIATE_SUBS_FAIL'
export const GET_AFFILIATE_SUBS_SUCCESS = 'GET_AFFILIATE_SUBS_SUCCESS'

//Producer
export const GET_PRODUCER_STATS = 'GET_PRODUCER_STATS'
export const GET_PRODUCER_STATS_FAIL = 'GET_PRODUCER_STATS_FAIL'
export const GET_PRODUCER_STATS_SUCCESS = 'GET_PRODUCER_STATS_SUCCESS'
export const GET_PRODUCER_RANKING = 'GET_PRODUCER_RANKING'
export const GET_PRODUCER_RANKING_FAIL = 'GET_PRODUCER_RANKING_FAIL'
export const GET_PRODUCER_RANKING_SUCCESS = 'GET_PRODUCER_RANKING_SUCCESS'
export const GET_PRODUCER_BEATS = 'GET_PRODUCER_BEATS'
export const GET_PRODUCER_BEATS_FAIL = 'GET_PRODUCER_BEATS_FAIL'
export const GET_PRODUCER_BEATS_SUCCESS = 'GET_PRODUCER_BEATS_SUCCESS'
export const RESET_PRODUCER = 'RESET_PRODUCER'

//Visitor
export const COOKIE_CONSENT_TRUE = 'COOKIE_CONSENT_TRUE'
export const COOKIE_CONSENT_FALSE = 'COOKIE_CONSENT_FALSE'
export const RESET_VISITOR = 'RESET_VISITOR'
export const CHECK_GDPR_REQUIRED_FALSE = 'CHECK_GDPR_REQUIRED_FALSE'
export const CHECK_GDPR_REQUIRED_TRUE = 'CHECK_GDPR_REQUIRED_TRUE'

//System check
export const INITIALIZE_FAIL = 'INITIALIZE_FAIL'
export const HEALTHCHECK_SUCCESS = 'HEALTHCHECK_SUCCESS'
export const EXPIRED_TOKEN = 'EXPIRED_TOKEN'
export const SET_APP_VERSION = 'SET_APP_VERSION'
export const SET_ERRORS = 'SET_ERRORS'
export const LOAD_MEMBERSHIPS = 'LOAD_MEMBERSHIPS'
export const RESET_APP = 'RESET_APP'
